@font-face {
    font-family: 'CrimsonFoam';
    src: url('./fonts/CrimsonFoamFont.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Shadows+Into+Light&display=swap');
  
  body section header{
    color: #FFFFFF;
  }